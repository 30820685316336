export const RepostIcon = (props) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3938 10.1127L12.0501 12.4564C12.0066 12.5001 11.955 12.5348 11.8981 12.5584C11.8412 12.5821 11.7802 12.5942 11.7186 12.5942C11.6571 12.5942 11.5961 12.5821 11.5392 12.5584C11.4823 12.5348 11.4307 12.5001 11.3872 12.4564L9.04349 10.1127C8.97773 10.0472 8.9329 9.96366 8.91469 9.87267C8.89648 9.78167 8.90571 9.68732 8.94122 9.60159C8.97673 9.51586 9.03691 9.44261 9.11413 9.39114C9.19134 9.33966 9.2821 9.31229 9.3749 9.3125H10.3124V3.21875H7.96865C7.84434 3.21872 7.72513 3.16932 7.63724 3.08141L5.76224 1.20641C5.69671 1.14085 5.65208 1.05734 5.634 0.966421C5.61592 0.875506 5.62521 0.781271 5.66068 0.695631C5.69615 0.60999 5.75621 0.536789 5.83328 0.485281C5.91035 0.433772 6.00096 0.40627 6.09365 0.40625H11.2499C12.284 0.40625 13.1249 1.24719 13.1249 2.28125V9.3125H14.0624C14.1552 9.31229 14.246 9.33966 14.3232 9.39114C14.4004 9.44261 14.4606 9.51586 14.4961 9.60159C14.5316 9.68732 14.5408 9.78167 14.5226 9.87267C14.5044 9.96366 14.4596 10.0472 14.3938 10.1127ZM9.18693 11.7425L7.36256 9.91859C7.27467 9.83068 7.15546 9.78128 7.03115 9.78125H4.6874V3.6875H5.6249C5.7176 3.68748 5.8082 3.65998 5.88527 3.60847C5.96234 3.55696 6.0224 3.48376 6.05787 3.39812C6.09334 3.31248 6.10263 3.21824 6.08455 3.12733C6.06647 3.03641 6.02184 2.9529 5.95631 2.88734L3.61256 0.543594C3.52465 0.455717 3.40545 0.40635 3.28115 0.40635C3.15685 0.40635 3.03765 0.455717 2.94974 0.543594L0.605994 2.88734C0.540458 2.9529 0.49583 3.03641 0.477752 3.12733C0.459673 3.21824 0.468956 3.31248 0.504426 3.39812C0.539896 3.48376 0.599962 3.55696 0.677029 3.60847C0.754096 3.65998 0.844705 3.68748 0.9374 3.6875H1.8749V10.7188C1.8749 11.7528 2.71584 12.5938 3.7499 12.5938H8.91553C9.01503 12.5938 9.11199 12.5623 9.19236 12.5036C9.27273 12.4449 9.33234 12.3622 9.36257 12.2674C9.3928 12.1726 9.39207 12.0706 9.36049 11.9763C9.32892 11.8819 9.26813 11.8 9.18693 11.7425Z"
        fill={props?.color ?? '#5B5B5B'}
      />
    </svg>
  );
};
