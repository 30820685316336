export const QuoteIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 15 15"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19226 6.92308H3.17301C2.93261 6.92308 2.72823 6.83896 2.55995 6.67068C2.39176 6.50239 2.30755 6.29808 2.30755 6.05761V5.76929C2.30755 5.13223 2.53295 4.58841 2.98373 4.13763C3.43447 3.68701 3.97839 3.46161 4.61538 3.46161H5.19226C5.34849 3.46161 5.48365 3.40449 5.59789 3.29036C5.71206 3.17615 5.76914 3.04099 5.76914 2.88473V1.73078C5.76914 1.57456 5.71202 1.43926 5.59789 1.32506C5.48369 1.21102 5.34852 1.15381 5.19226 1.15381H4.61538C3.99032 1.15381 3.39399 1.27562 2.82602 1.51889C2.2581 1.76232 1.76688 2.09138 1.35217 2.5061C0.93748 2.92063 0.608516 3.41188 0.365116 3.97986C0.121716 4.54777 0 5.14426 0 5.76925V12.1152C0 12.5963 0.168192 13.0047 0.504734 13.3414C0.841307 13.6778 1.25006 13.8461 1.7308 13.8461H5.19248C5.67319 13.8461 6.08181 13.6778 6.41842 13.3414C6.7549 13.0047 6.92318 12.5963 6.92318 12.1152V8.65387C6.92318 8.17294 6.7549 7.76451 6.4182 7.42781C6.08175 7.09136 5.67297 6.92308 5.19226 6.92308ZM14.4955 7.42781C14.159 7.09136 13.7504 6.92308 13.2696 6.92308H11.2503C11.0101 6.92308 10.8055 6.83896 10.6375 6.67068C10.4691 6.50239 10.3851 6.29808 10.3851 6.05761V5.76929C10.3851 5.13223 10.6105 4.58841 11.061 4.13763C11.5116 3.68701 12.0555 3.46161 12.6928 3.46161H13.2696C13.4258 3.46161 13.5611 3.40449 13.6752 3.29036C13.7893 3.17615 13.8466 3.04099 13.8466 2.88473V1.73078C13.8466 1.57456 13.7893 1.43926 13.6752 1.32506C13.5611 1.21102 13.4259 1.15381 13.2696 1.15381H12.6928C12.0674 1.15381 11.4712 1.27562 10.9031 1.51889C10.3353 1.76232 9.84421 2.09138 9.42949 2.5061C9.01477 2.92063 8.68565 3.41188 8.44234 3.97986C8.19901 4.54777 8.07713 5.14426 8.07713 5.76925V12.1152C8.07713 12.5963 8.24548 13.0047 8.58193 13.3414C8.91841 13.6778 9.32706 13.8461 9.80786 13.8461H13.2694C13.7502 13.8461 14.1588 13.6778 14.4953 13.3414C14.832 13.0047 15 12.5963 15 12.1152V8.65387C15 8.17291 14.832 7.76451 14.4955 7.42781Z"
        fill={props?.color ?? '#5B5B5B'}
      />
    </svg>
  );
};
