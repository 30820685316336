export const ReplyCountIcon = (props) => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1027_33411)">
        <path
          d="M10.6152 7.70459L15.6152 12.7046L10.6152 17.7046"
          stroke={props?.color ?? '#5B5B5B'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.61523 2.87451C1.61523 4.62787 1.61523 6.83023 1.61523 8.70566C1.61523 10.9148 3.4061 12.7046 5.61523 12.7046H15.6152"
          stroke={props?.color ?? '#5B5B5B'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
